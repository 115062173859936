/* quill-overrides.css */

/* Asegúrate de que el contenedor ocupe el ancho completo del bloque de contenido */
.chart-container-center {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
}

.chart-container-left {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 1rem 0;
}

.chart-container-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 1rem 0;
}

/* Resto de las clases CSS de Quill */
.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-left {
  text-align: left;
}

.ql-font-serif {
  font-family: serif;
}

.ql-font-monospace {
  font-family: monospace;
}

/* Encabezados */
h1,
.ql-editor h1 {
  font-size: 1.875rem;
  font-weight: bold;
  margin: 1rem 0;
}

h2,
.ql-editor h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0.75rem 0;
}

h3,
.ql-editor h3 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0.5rem 0;
}

/* Formatos básicos */
.ql-editor b,
.ql-editor strong {
  font-weight: bold;
}

.ql-editor i,
.ql-editor em {
  font-style: italic;
}

.ql-editor u {
  text-decoration: underline;
}

.ql-editor s {
  text-decoration: line-through;
}

/* Bloques de código */
.ql-code-block {
  background-color: #f3f4f6;
  padding: 1rem;
  border-radius: 0.375rem;
  margin: 1rem 0;
  font-family: monospace;
}

/* Citas */
.ql-blockquote {
  border-left: 0.25rem solid #9ca3af;
  padding-left: 1rem;
  font-style: italic;
  margin: 1rem 0;
}

/* Listas */
.ql-list.ql-ordered,
ol {
  list-style-type: decimal;
  padding-left: 1.5rem;
  margin: 0.5rem 0;
}

.ql-list.ql-bullet,
ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin: 0.5rem 0;
}


