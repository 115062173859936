@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 84px;
}
body {
  font-family: 'Poppins', sans-serif !important;
  background: #f0f0f0;
}

code {
  font-family: 'Poppins', sans-serif !important;
}
@layer base {
  html {
    font-family: 'Poppins', sans-serif !important;
    background-color: #f5f5f5;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;




/* .ant-select-selector {
  border-radius: 9999px !important;
  border:none !important;
  font-family: 'Poppins'  !important;
  font-size:14px;
  font-weight: 400 !important;
  color: #F0F0F0 !important;
  background-color: #4CACBC !important;
  box-shadow: none !important;
  &:hover {
    border-color: #3e9aaa !important;
  }
  &:focus {
    border-color: #3e9aaa !important;
    outline: 0;
    box-shadow: none !important;
  }
  &:active {
    border-color: #3e9aaa !important;
    outline: 0;
    box-shadow: none !important;
  }
  &::placeholder {
    color: #F0F0F0!important;
  }
} */

.ant-layout{
  background-color: #F0F0F0;
}

.ant-input{
  padding: 3px 14px !important;
}

.ant-form{
  font-family: 'Poppins', sans-serif !important;
  font-size:16px;
}

.ant-form-item-explain-error{
  font-family: 'Poppins'  !important;
  font-size:16px !important;
  font-weight: 300 !important;
}

.ant-select-dropdown .ant-select-item {
  /* color: #F0F0F0 !important; */
  font-family: 'Poppins'  !important;
  font-size:16px;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #3e9aaa !important;
  /* color: #F0F0F0 !important; */
  font-family: 'Poppins'  !important;
  font-size:16px;
}
.ant-select .ant-select-selection-placeholder{
  /* color: #F0F0F0 !important; */
  font-family: 'Poppins'  !important;
}
.ant-select .ant-select-arrow{
  /* color: #F0F0F0 !important; */
  font-family: 'Poppins'  !important;
}

.ant-modal{
  font-family: 'Poppins'  !important;
}

.ant-breadcrumb {
  font-family: 'Poppins'  !important;
}

.ant-float-btn-body{
  font-family: 'Poppins'  !important;
}

.modalConfirm .ant-modal-content{
  padding: 55px 28px;
}

.menu-tabs {
  justify-content: center;
  font-size: 22px;
  color: #a8abab;
  flex: 1 1 auto;
  height: 100%;
  align-items: center;
}

.component-user {
  align-items: center;
  display: flex;
}

.cardFormHome {
  max-width: 480px;
}

.formhome {
  margin-bottom: 0px;
}

.formhome .ant-select-selector {
  background-color: #66ABBA!important;
  border-color: #66ABBA!important;
  border-radius: 20px;

}
.formhome .ant-select-selector .ant-select-selection-placeholder {
  color: #47472a !important;
}

.multiStyle .ant-select-selection-item{
  background-color: #60abaf !important;
}

.ant-tabs-nav-wrap{
  z-index: 50;
}
.ant-tabs-nav-list{
  padding-left: 3px;
  padding-top: 3px;
}

.ant-tabs{
  font-family: 'Poppins' !important;
}
.ant-dropdown .ant-dropdown-menu-item{
  font-family: 'Poppins' !important;
}

.ant-tabs-tab {
  color: white !important;
  background-color: #707070 !important;
  margin-right: 16px !important;
}

.ant-tabs .ant-tabs-tab{
  font-size: medium;
}

.ant-tabs-tab-active {
  background-color: white !important;
  border-bottom-color: white;
  box-shadow: 0px 0px 5px #707070
}

.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #707070 !important;
}

.ant-tabs-nav {
  margin: 0 !important;
}

.shadow-body{
  border-top-color: white;
  border-top-width: 0;
  box-shadow: 0px -0.5px 5px #8f8e8e, 0px 0.5px 5px #8f8e8e;
}

.shadow-body-indicator{
  border-top-color: white;
  border-top-width: 0;
  box-shadow: 0px -0.5px 5px #d6d0d0, 0px 0.5px 5px #d6d0d0;
  border-end-end-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.shadow-indicator .ant-tabs-tab-active {
  background-color: white !important;
  border-bottom-color: white;
  box-shadow: 0px 0px 3px #c5bfbf
}

.button-underline{
  border-bottom: solid;
  border-bottom-color: #6DBFA4;
}


.btn-filter svg g > *{
  stroke:black;
}
.btn-filter:hover svg g > *{
  stroke:red;
}
.btn-filter svg{
  width: 15px;
  height: 15px;
}

.space-center .ant-space-item{
  align-self: center;
}

.table-pointer .ant-table-row{
  cursor: pointer;
}

.no-margin-drop .ant-row{
  margin: 0 !important;
}

.dataCard {
  background-color: #efefef;
  border-radius: 0.5em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1em;
}

.title-views{
  font-size: 25px;
  letter-spacing: 0px;
  color: #4CACBC;
  opacity: 1;
  font-weight: bold;
}

.float-statement .ant-float-btn-body{
  background-color: #8ACDD0;
}

.float-statement .ant-float-btn-body:hover{
  background-color: #60abaf;
}

.treeMunicipality .ant-select-selector{
  padding-left: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.treeMunicipality .ant-select-selection-placeholder  {

text-align: left;
color: #afaeae;
font-weight: 200;
}

.treeMunicipality .ant-select-selection-item{
  background-color: #4CACBC !important;
}

.select-share .ant-select-selection-item{
  background-color: #4CACBC !important;
}

 .ant-form-item-label label{
  height: auto !important;
 }

 .select-tags .ant-select-selection-item{
  background-color: #60abaf !important;
 }

 .select-tags input{
  color: black !important;
  font-weight: 300;
 }

 .select-tags .ant-select-selector{
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
 }

 .level-tree-0{
  font-weight: 700;
  color: #676767;
 }
 .level-tree-1{
  font-weight: 500;
  margin-left: 10px;
  color: #424141;
  border-bottom-width: 1px;
  border-color: #cccaca;
 }
 .level-tree-2{
  font-weight: 300;
  margin-left: 20px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-color: #cccaca;
 }
 .level-tree-3{
  font-weight: 300;
  font-size: 14px;
  margin-left: 20px;
  padding-left: 38px !important;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-color: #cccaca;
 }
/*  .level-tree-3 p{
   display: list-item;
   list-style-type: circle
 } */
 .level-tree-4{
  font-weight: 300;
  font-size: 12px;
  margin-left: 20px;
  padding-left: 68px !important;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-color: #cccaca;
 }

 .parent-level-tree-0{
  margin-top: 8px;
 }
 .parent-level-tree-1{
  margin-top: 8px;
 }
 .parent-level-tree-2{
  margin-top: 0px;
 }
 .parent-level-tree-3{
  margin-top: 0px !important;
 }

 .tree-last p{
  display: list-item;
  list-style-type: circle;
  margin-left: 20px !important;
 }
 

 .select-no-style .ant-select-selector{
  border: none !important;
 }
 .select-no-style .ant-select-selection-item{
  padding-bottom: 3px !important
 }
/* .ant-tooltip-inner:has(> .float-statement-tooltip){
  background-color: #F0F0F0;
  color: black;
} */

.ant-switch-checked{
  background: #c0ded4 !important;
}

.ant-switch .ant-switch-handle::before{
  background-color: lightslategrey;
}

.ant-switch-checked .ant-switch-handle::before{
  background-color: #6dbfa4;
}


.ant-switch .ant-switch-inner{
  border-width: thin;
  border-color: lightslategrey;
}

.ant-switch-checked .ant-switch-inner{
  border-width: 0;
}

.chart-icon:hover{
  fill:#0066cc
}