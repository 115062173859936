  /* styles.css */
/* .ant-table-thead > tr > th {
    background: #F0F0F0 !important; Elimina el fondo si es necesario 
    color: #4CACBC !important;  El color del texto 
    padding: 8px 8px !important;  El padding de las celdas 
    
  } */

/*   .ant-table-thead > tr > th:not(:last-child) {
    border-right: 1px solid rgb(141 130 130 / 31%) !important;
    
  } */

/* 
  
  .ant-table-tbody
    {
        background:#F0F0F0 ;
    } */
/* new  */

/* Asegúrate de incluir este CSS en tus hojas de estilo globales o en un módulo de CSS si estás utilizando CSS Modules */

/* .ant-table-thead > tr > th:not(:last-child) {
  box-shadow: 3px 0 0 #4CACBC !important;
} */

/* Elimina el borde derecho de la última columna */
.ant-table-thead > tr > th:last-child {
  border-right: none !important;
}
.ant-table-tbody > tr > td:last-child {
  border-right: none !important;
}

.custom-row-style-view {
  box-shadow: 0px 1px 0px #00000029;
  overflow-wrap: "anywhere" !important;
}

.table-break-words td{
  overflow-wrap: anywhere !important;
  white-space: inherit !important;
}

.custom-row-style {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
}

.custom-row-style-view .ant-table-cell{
  border-right: 1px solid rgb(141 130 130 / 31%) !important;
  padding: 0px 3px !important;
}

.custom-row-style-view .ant-table-thead > tr > th:not(:last-child) {
  border-right: 1px solid rgb(141 130 130 / 31%) !important;
}
.custom-row-style-view .ant-table-thead > tr > th {
  border-bottom: 1px solid rgb(141 130 130 / 31%) !important;
}
.ant-table-wrapper .ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgb(44, 51, 51);
  font-size: 16px;
  line-height: 1.5;
  list-style: none;
  font-family: Poppins, sans-serif;
  background: rgb(240, 240, 240);
  border-radius: 9px 9px 0 0;
  scrollbar-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.1) !important;
}

